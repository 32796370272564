import {
  Box,
  Button,
  Icon,
  Image,
  Link,
  Modal,
  ModalProps,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ResponsiveValue,
  Text,
  ThemeTypings,
  useDisclosure,
} from "@chakra-ui/react"
import * as React from "react"
import { FaPinterest } from "react-icons/fa"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { generatePinterestHref } from "./socialSharing"

type LightboxSize = ThemeTypings["components"]["Modal"]["sizes"] | (string & {})

interface LightboxProps {
  alt: string
  image: IGatsbyImageData
  modalProps?: ModalProps
  pinterestTitle?: string
  pinterestUrl?: string
  showCaption?: boolean | ResponsiveValue<boolean>
  ref?: React.MutableRefObject<HTMLDivElement>
  size?: LightboxSize
}

function ImageWithLightbox({
  alt,
  image,
  showCaption = false,
  modalProps = null,
  pinterestTitle = null,
  pinterestUrl = null,
  size = `6xl`,
}: LightboxProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const includePinterest = pinterestTitle && pinterestUrl

  return (
    <>
      <Box h="100%" pos="relative" role="group" w="100%">
        <Image
          alt={alt}
          as={GatsbyImage}
          className="rounded"
          h="100%"
          image={image}
          style={{ objectFit: `cover` }}
          rounded="lg"
          w="100%"
        />
        <Button
          alt={alt}
          aria-label="Open this image in a lightbox"
          h="100%"
          image={image}
          left="0"
          onClick={onOpen}
          pos="absolute"
          rounded="lg"
          top="0"
          variant="unstyled"
          w="100%"
        />
        {includePinterest && (
          <Box
            bg="rgb(230, 0, 35)"
            color="white"
            fontWeight="bold"
            left="2"
            opacity={{ base: 1, md: 0 }}
            px={2}
            pos="absolute"
            rounded="md"
            top="2"
            transition="0.2s"
            _groupHover={{ opacity: 1 }}
            _hover={{ bg: `rgb(210, 0, 35)` }}
          >
            <Link
              align="center"
              d="flex"
              href={generatePinterestHref(
                pinterestUrl,
                image.images.fallback.src,
                pinterestTitle
              )}
              isExternal
              _hover={{ textDecoration: `none` }}
            >
              <Icon as={FaPinterest} mr={1} mt={1} /> Pin It
            </Link>
          </Box>
        )}
      </Box>
      <Modal
        allowPinchZoom
        isCentered
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        {...modalProps}
      >
        <ModalOverlay bg="blackAlpha.800" />
        <ModalContent bg="transparent" p={2}>
          <ModalCloseButton
            bg="blackAlpha.500"
            color="white"
            mr={2}
            mt={3}
            p={4}
            rounded="full"
            zIndex="overlay"
            _hover={{ bg: `blackAlpha.600` }}
          />
          <Image
            alt={alt}
            as={GatsbyImage}
            h="100%"
            image={image}
            rounded="lg"
            w="100%"
          />
          {isOpen && showCaption && (
            <Text
              color="white"
              mx="auto"
              my={2}
              px={2}
              top="100%"
              zIndex="overlay"
            >
              {alt}
            </Text>
          )}
        </ModalContent>
      </Modal>
    </>
  )
}

export default ImageWithLightbox
