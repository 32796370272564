import * as React from "react"
import {
  Box,
  chakra,
  Flex,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react"
import { InfoOutlineIcon } from "@chakra-ui/icons"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { useLocation } from "@reach/router"

import PlanCard from "./planCard"
import ImageWithLightbox from "./imageWithLightbox"
import { IPlanFilter, TLotShape, TLotSlope } from "./plans"
import { ISeriesProps, IPlanFilterUpdaterProps } from "./series"
import { MediaContext } from "../components/CoreUI/MediaContext"
import slugIt from "../components/CoreUI/utils/slugIt"
import type CoreUI from "../components/CoreUI/types"

const LOT_SHAPES: readonly TLotShape[] = [
  `Any`,
  `Deep & Narrow`,
  `Mostly Square`,
  `Shallow & Wide`,
]
const LOT_SLOPES: readonly TLotSlope[] = [
  `Any`,
  `Mostly Flat`,
  `Slopes Down`,
  `Slopes Up`,
]

const DEFAULT_PLAN_FILTER: IPlanFilter = {
  baths: 0,
  beds: 0,
  garage: 0,
  levels: 0,
  // lotShape: 0,
  // lotSlope: 0,
  sqft: 0,
}

function isDefaultFilter(currentFilter: IPlanFilter): boolean {
  const curKeys = Object.keys(currentFilter)
  const defKeys = Object.keys(DEFAULT_PLAN_FILTER)

  if (curKeys.length !== defKeys.length) {
    return false
  }

  for (let key of curKeys) {
    if (currentFilter[key] !== DEFAULT_PLAN_FILTER[key]) {
      return false
    }
  }

  return true
}

function calcFeaturedWidth(
  numCols: number,
  numPlans: number,
  pos: `first` | `last`
): string {
  const remainder = numPlans % numCols
  const colsRemaining = remainder / numCols

  if (colsRemaining === 0) {
    const multiplier = pos === `first` ? 1 : numCols - 1
    const percentWidth = Math.floor((1 / numCols) * multiplier * 100) - numCols
    return `${percentWidth}%`
  }

  if (colsRemaining === 2 / 3) {
    const multiplier = 2
    const percentWidth = Math.floor((1 / numCols) * multiplier * 100) - numCols
    return `${percentWidth}%`
  }

  if (colsRemaining === 1 / 3 || colsRemaining === 1 / 2) {
    const multiplier = pos === `last` && numCols === 2 ? 2 : 1
    const percentWidth = Math.floor((1 / numCols) * multiplier * 100) - numCols
    return `${percentWidth}%`
  }
}

function HomeSeries(props: ISeriesProps): JSX.Element {
  gsap.registerPlugin(ScrollTrigger)

  const { origin } = useLocation()
  const { series } = props

  const seriesWrapperID = series.name.replace(/ /g, `-`).toLowerCase()
  const filteredPlans = series.plans
  const seriesUrl = `${origin}/home-series/${slugIt(series.name)}/`

  return (
    <chakra.div id={seriesWrapperID} display="block">
      <Flex
        align="center"
        direction="column"
        justify="center"
        py={{ base: 2, sm: 0 }}
      >
        <Wrap justify="center" maxW="1200px" px={[2, 4, 6, 8]} w="100%">
          {`featuredExterior` in series && (
            <WrapItem
              key={`${series.name}-featured-1`}
              p={2}
              pos="relative"
              w={{
                base: `98%`,
                md: calcFeaturedWidth(2, filteredPlans.length, `first`),
                lg: calcFeaturedWidth(3, filteredPlans.length, `first`),
              }}
            >
              <ImageWithLightbox
                alt={series.featuredExterior.description}
                image={series.featuredExterior.gatsbyImageData}
                pinterestTitle={series.name}
                pinterestUrl={seriesUrl}
                showCaption
              />
              {series.featuredExterior.description && (
                <Flex
                  align="start"
                  bgGradient="linear-gradient(to-b, transparent, blackAlpha.700)"
                  bottom="0"
                  color="white"
                  fontSize="sm"
                  left="0"
                  m={2}
                  p={2}
                  pt={4}
                  pos="absolute"
                  roundedBottom="lg"
                  w="calc(100% - 16px)"
                >
                  <InfoOutlineIcon mr="6px" mt={1} />
                  <Box
                    textShadow="0 1px 0 black"
                    _selection={{ background: `black` }}
                  >
                    {series.featuredExterior.description}
                  </Box>
                </Flex>
              )}
            </WrapItem>
          )}
          {filteredPlans.map((plan, i) => (
            <WrapItem key={i} w={{ base: `98%`, md: `48%`, lg: `31%` }}>
              <PlanCard {...plan} />
            </WrapItem>
          ))}
          {`featuredInterior` in series && (
            <WrapItem
              key={`${series.name}-featured-2`}
              p={2}
              pos="relative"
              w={{
                base: `98%`,
                md: calcFeaturedWidth(2, filteredPlans.length, `last`),
                lg: calcFeaturedWidth(3, filteredPlans.length, `last`),
              }}
            >
              <ImageWithLightbox
                alt={series.featuredInterior.description}
                image={series.featuredInterior.gatsbyImageData}
                pinterestTitle={series.name}
                pinterestUrl={seriesUrl}
                showCaption
              />
              {series.featuredInterior.description && (
                <Flex
                  align="start"
                  bgGradient="linear-gradient(to-b, transparent, blackAlpha.700)"
                  bottom="0"
                  color="white"
                  fontSize="sm"
                  left="0"
                  m={2}
                  mr={4}
                  p={2}
                  pt={4}
                  pos="absolute"
                  roundedBottom="lg"
                  w="calc(100% - 16px)"
                >
                  <InfoOutlineIcon mr="6px" mt={1} />
                  <Box
                    textShadow="0 1px 0 black"
                    _selection={{ background: `black` }}
                  >
                    {series.featuredInterior.description}
                  </Box>
                </Flex>
              )}
            </WrapItem>
          )}
        </Wrap>
      </Flex>
    </chakra.div>
  )
}

export default HomeSeries
