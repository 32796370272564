import * as React from "react"
import { Box, Flex, Heading, Select, Text } from "@chakra-ui/react"
import { graphql, PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"

import Layout from "../components/layout"
import HomeSeries from "../components/homeSeries"
import { IHomeSeries } from "../components/series"
import BlockHeader from "../components/blockHeader"
import SEO from "../components/CoreUI/SEO"

interface HomePlansProp extends PageProps {
  data: {
    allSeries: {
      nodes: IHomeSeries[]
    }
  }
}

const Page = ({ data, location }: HomePlansProp) => {
  const introWrapperRef = React.useRef<HTMLDivElement>(null)
  const introBgImgRef = React.useRef<HTMLDivElement>(null)
  const introOverlayRef = React.useRef<HTMLDivElement>(null)
  const introHeadingRef = React.useRef<HTMLDivElement>(null)
  const introSubHeadingRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    gsap.from(introHeadingRef?.current, {
      delay: 0.5,
      duration: 1.5,
      opacity: 0,
    })
    gsap.from(introSubHeadingRef?.current, {
      delay: 2,
      duration: 1,
      opacity: 0,
    })
  }, [])

  const seriesColors = data.allSeries.nodes.map(
    ({ primaryColor }) => primaryColor || `red.500`
  )

  const blockWidth = 100 / seriesColors.length
  let gradientRules = `repeating-linear-gradient(120deg,`

  let currentBlock = 0
  seriesColors.forEach((color: string, i: number) => {
    gradientRules += `${color} ${currentBlock}%,`
    currentBlock += blockWidth
    gradientRules += `${color} ${currentBlock}%`
    if (i + 1 < seriesColors.length) {
      gradientRules += `, `
    }
  })
  gradientRules += `)`

  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Our stylish and customizable home plans host families all over Western North Carolina. Discover the perfect plan for your mountain land or a city homesite.",
          image: `${location.origin}/home-on-bear-lake.jpg`,
          imageAlt: `A Sundog-built home on Bear Lake`,
        }}
        title="Home Plans, Your Home Your Way | Sundog Homes"
        url={location.href}
      />
      <Box h="50vh" pos="relative" ref={introWrapperRef} w="100vw">
        <Box h="100%" l="0" pos="absolute" ref={introBgImgRef} t="0" w="100%">
          <StaticImage
            alt="A custom Sundog home on Bear Lake in Tuckaseegee, NC."
            className="squared"
            src="../images/home-on-bear-lake.jpg"
            layout="fullWidth"
            style={{ height: `100%` }}
            transformOptions={{
              fit: `cover`,
              // duotone: {
              //   highlight: `#E53E3E`,
              //   shadow: `#333333`,
              // },
            }}
          />
        </Box>
        <Flex
          align="center"
          bg="blackAlpha.500"
          color="white"
          direction="column"
          h="100%"
          justify="center"
          left="0"
          mb={6}
          px={[4, 6, 8]}
          pos="absolute"
          ref={introOverlayRef}
          t="0"
          w="100%"
        >
          <Box>
            <Heading
              fontSize="4xl"
              fontWeight="extrabold"
              ref={introHeadingRef}
            >
              Your Home, Your Way
            </Heading>
            <Text
              fontSize="xl"
              maxW={[`90vw`, `80vw`, `65vw`]}
              mt={4}
              ref={introSubHeadingRef}
            >
              Life is full of things that can't be planned. Your home shouldn't
              be one of them.
            </Text>
          </Box>
        </Flex>
      </Box>
      <Box bgGradient={gradientRules} h="10px" w="100vw" />
      <Flex
        align="center"
        direction="column"
        justify="center"
        maxW="800px"
        mb={6}
        mx="auto"
        pt={12}
        px={[2, 4, 6, 8]}
      >
        <BlockHeader>Home Plans</BlockHeader>
        <Text
          fontSize="5xl"
          fontFamily="heading"
          fontWeight="black"
          lineHeight="shorter"
          mt={2}
        >
          Pick a place to start
        </Text>
        <Text fontSize="xl" maxW="95vw" mt={4} textAlign="center" w="500px">
          Usually used as jumping off points and sometimes built unchanged, our
          plans are designed to solve everyday problems in comfort and style —
          and engineered to last for generations.
        </Text>
      </Flex>
      <Flex
        align="center"
        direction="column"
        justify="center"
        mx="auto"
        my={8}
        px={[2, 4, 6, 8]}
      >
        {data.allSeries.nodes.map((series: IHomeSeries, i: number) => (
          <HomeSeries key={i} series={series} />
        ))}
      </Flex>
    </Layout>
  )
}

export default Page

export const query = graphql`
  {
    allSeries: allContentfulHomeSeries(
      sort: { fields: displayOrder, order: ASC }
    ) {
      nodes {
        ...HomeSeries
      }
    }
  }
`
