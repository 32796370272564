import * as React from "react"
import {
  Badge,
  Box,
  Button,
  chakra,
  Image,
  Stack,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { IHomePlan } from "./plans"

function PlanCard({
  baths,
  beds,
  description,
  elevations,
  isNew,
  name,
  slug,
  sqft,
}: IHomePlan): JSX.Element {
  return (
    <VStack h="100%" spacing={0} align="center">
      <Box
        border="1px"
        borderColor="gray.400"
        borderRadius="xl"
        h="100%"
        shadow={isNew ? `inset 0 0 3px -1px green, 0 0 3px -1px green` : `none`}
        p={2}
        pos="relative"
        transitionDuration="0.3s"
        _hover={{ shadow: `md` }}
      >
        <Image
          alt={elevations[0].description}
          as={GatsbyImage}
          borderRadius="xl"
          className="rounded"
          image={elevations[0].gatsbyImageData}
          m={0}
          objectFit="cover"
          w="100%"
        />
        {isNew ? (
          <Box p={2} pos="absolute" right={1} top="0">
            <Badge borderRadius="lg" colorScheme="purple">
              New
            </Badge>
          </Box>
        ) : null}
        <Box
          borderRadius="xl"
          mt="4px"
          p={0}
          pos="relative"
          overflow="hidden"
          w="100%"
        >
          <Box p={2}>
            <Box d="flex" alignItems="baseline" mt={0}>
              <Stack
                color="gray.800"
                direction="row"
                divider={<StackDivider borderColor="gray.200" />}
                fontSize="xs"
                fontWeight="semibold"
                justify="space-between"
                letterSpacing="wide"
                px={1}
                py={2}
                rounded="md"
                textTransform="uppercase"
                w="100%"
              >
                <chakra.span>{beds} beds</chakra.span>
                <chakra.span>{baths} baths</chakra.span>
                <chakra.span>{sqft.toLocaleString(`en-US`)} sqft</chakra.span>
              </Stack>
              {/* <Box
                  py={2}
                  color="gray.800"
                  fontWeight="semibold"
                  fontSize="xs"
                  letterSpacing="wide"
                  ml={isNew ? 0 : 0}
                  textTransform="uppercase"
                >
                  {beds} beds • {baths} baths • 1,543 sqft
                </Box> */}
            </Box>
            <Box as="h4" fontWeight="semibold" fontSize="lg" lineHeight="short">
              {name}
            </Box>
            <Box fontSize="md" lineHeight="short" mt={1}>
              <Text noOfLines={5}>{description.description}</Text>
            </Box>
            <Box mt={4} mx="auto">
              <Link
                to={`/home-plans/${slug}/`}
                state={{ isLocalTraffic: true }}
              >
                <Button
                  colorScheme="green"
                  isFullWidth
                  mx="auto"
                  maxW={{ base: `100%`, sm: `200px` }}
                  shadow="md"
                  size="md"
                >
                  View This Plan
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </VStack>
  )
}

export default PlanCard
