function slugIt(string, options) {
  const opts = {
    invalidChars: null,
    lowercase: true,
    postFilters: [],
    preFilters: [],
    separator: `-`,
    ...options,
  }

  // define the standard filters with semantic names for maximum read-good!
  const allInvalidSlugChars = {
    pattern: opts.invalidChars || new RegExp(/['"!?#+();]/g),
  }

  const allAmpersands = {
    pattern: new RegExp(/&/g),
    replacement: `and`,
  }

  const allBlankSpaces = {
    pattern: new RegExp(/ /g),
    replacement: opts.separator,
  }

  const allRepeatedSeparators = {
    pattern: new RegExp(`\\${opts.separator}{1,}`, `g`),
    replacement: opts.separator,
  }

  const dashAtStart = {
    pattern: new RegExp(/^(-)/),
  }

  const dashAtEnd = {
    pattern: new RegExp(/(-)$/),
  }

  // copy any provided pre-filters and all standard filters into an array in
  // preparation for maximum functional javascript!!
  const filters = [
    ...opts.preFilters,
    allInvalidSlugChars,
    allAmpersands,
    allBlankSpaces,
    allRepeatedSeparators,
    dashAtStart,
    dashAtEnd,
    ...opts.postFilters,
  ]

  // if the lowercase option is true -— it is by default —- perform all
  // mutations on a lowercased copy of the given string. if a filter lacks
  // a replacement value, replace any matches for that filter with nothing
  // (aka remove them).

  // now perform maximum functional javascript!!!
  const slug = filters.reduce(
    (mutant, { pattern, replacement = `` }) =>
      mutant.replace(pattern, replacement),
    opts.lowercase ? string.toLowerCase() : string
  )

  // slug achieved!!!!
  return slug
}

export default slugIt
